@media (min-width:1200px) {
  .swiper {
    width: 100%;
    height: 400px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .swiper-slide img {
    max-width: 4rem;
    height: 4rem;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5 rem solid var(--helper--tint);
  }
  .swiper-clinet-msg {
    padding: 3rem 6rem;
    background: var(--white);
    border-radius: 1rem;
    text-align: left;
    box-shadow: var(--shadow);
    position: relative;
    size: 1px;
  }
  .swiper-client-msg::before {
    content: "";
    position: absolute;
    bottom: -10rem;
    left: 50%;
    transform: transtateX(-50%);
    border: 5rem solid var(--white);
    border-color: transparent;
    border-top-color: var(--helper--tint);
  }
  .swiper-clinet-msg p::before {
    content: "\f10d";
    position: absolute;
    top: 1rem;
    left: 2.5rem;
    font-size: 2rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-msg p::after {
    content: "\f10e";
    position: absolute;
    bottom: 1rem;
    right: 3rem;
    font-size: 2rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .figure-img1, .figure-img2 {
    margin-bottom: 15px;
  }
  
  .figure-img1 img {
    width: 80%;  
    max-width: 200px;  
    border-radius: 60%; 
  }
  
  .figure-img2 {
    text-align: center;
  }
  
  .figure-img2 .para {
    margin: 0;
    color: #333;  
    font-size: 14px;  
  }
}


@media (min-width:992px) and (max-width: 1199px) {
  .swiper{
    width: 100%;
    height: 400px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .swiper-slide img {
    max-width: 4rem;
    height: 4rem;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5 rem solid var(--helper--tint);
  }
  .swiper-clinet-msg {
    padding: 1rem 4rem;
    background: var(--white);
    border-radius: 1rem;
    text-align: left;
    box-shadow: var(--shadow);
    position: relative;
    size: 0px;
  }
  .swiper-client-msg::before {
    content: "";
    position: absolute;
    bottom: -10rem;
    left: 50%;
    transform: transtateX(-50%);
    border: 5rem solid var(--white);
    border-color: transparent;
    border-top-color: var(--helper--tint);
  }
  .swiper-clinet-msg p::before {
    content: "\f10d";
    position: absolute;
    top: 0;
    left: 1rem;
    font-size: 2rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-msg p::after {
    content: "\f10e";
    position: absolute;
    bottom: 0;
    right: 3rem;
    font-size: 2rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .figure-img1, .figure-img2 {
    margin-bottom: 15px;
  }
  
  .figure-img1 img {
    width: 90%;  
    max-width: 300px;  
    border-radius: 100%; 
  }
  
  .figure-img2 {
    text-align: center;
  }
  
  .figure-img2 .para {
    margin: 0;
    color: #333;  
    font-size: 13px;  
  }
}

@media (min-width:768px) and (max-width: 991px) {
  .swiper{
    width: 100%;
    height: 330px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .swiper-slide img {
    max-width: 2.5rem;
    height: 2.5rem;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5 rem solid var(--helper--tint);
  }
  .swiper-clinet-msg {
    padding: 0rem 2rem;
    background: var(--white);
    border-radius: 1rem;
    text-align: left;
    box-shadow: var(--shadow);
    position: relative;
    size: 0px;
  }
  .swiper-client-msg::before {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 50%;
    transform: transtateX(-50%);
    border: 5rem solid var(--white);
    border-color: transparent;
    border-top-color: var(--helper--tint);
  }
  .swiper-clinet-msg p::before {
    content: "\f10d";
    position: absolute;
    top: 0;
    left: 0rem;
    font-size: 1.5rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-msg p::after {
    content: "\f10e";
    position: absolute;
    bottom: 0;
    right: 1rem;
    font-size: 1.5rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .figure-img1, .figure-img2 {
    margin-bottom: 15px;
  }
  
  .figure-img1 img {
    width: 80%;  
    max-width: 100px;  
    border-radius: 60%; 
  }
  
  .figure-img2 {
    text-align: center;
  }
  
  .figure-img2 .para {
    margin: 0;
    color: #333;  
    font-size: 8px;  
  }
}


@media (min-width:576px) and (max-width:767px){
  .swiper{
    width: 100%;
    height: 240px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .swiper-slide img {
    max-width: 2rem;
    height: 2rem;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5 rem solid var(--helper--tint);
  }
  .swiper-clinet-msg {
    padding: 0rem 1rem;
    background: var(--white);
    border-radius: 1rem;
    text-align: left;
    box-shadow: var(--shadow);
    position: relative;
    size: 0px;
  }
  .swiper-client-msg::before {
    content: "";
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: transtateX(-50%);
    border: 5rem solid var(--white);
    border-color: transparent;
    border-top-color: var(--helper--tint);
  }
  .swiper-clinet-msg p::before {
    content: "\f10d";
    position: absolute;
    top: 0;
    left: 0rem;
    top: -1rem;
    font-size: 1rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-msg p::after {
    content: "\f10e";
    position: absolute;
    bottom: 0;
    right: 1.5rem;
    font-size: 1rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  
  .figure-img1, .figure-img2 {
    margin-bottom: 15px;
  }
  
  .figure-img1 img {
    width: 80%;  
    max-width: 100px;  
    border-radius: 60%; 
  }
  
  .figure-img2 {
    text-align: center;
  }
  
  .figure-img2 .para {
    margin: 0;
    color: #333;  
    font-size: 9px;  
  }
}


@media (min-width:500px) and (max-width:575px){
  .swiper{
    width: 100%;
    height: 190px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 9px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .swiper-slide img {
    max-width: 2rem;
    height: 2rem;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    border: 0.5 rem solid var(--helper--tint);
  }
  .swiper-clinet-msg {
    padding: 0rem 0rem;
    background: var(--white);
    border-radius: 1rem;
    text-align: left;
    box-shadow: var(--shadow);
    position: relative;
    size: 0px;
  }
  .swiper-client-msg::before {
    content: "";
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: transtateX(-50%);
    border: 5rem solid var(--white);
    border-color: transparent;
    border-top-color: var(--helper--tint);
  }
  .swiper-clinet-msg p::before {
    content: "\f10d";
    position: absolute;
    top: 0;
    left: 0rem;
    top: -1.5rem;
    font-size: 1rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-msg p::after {
    content: "\f10e";
    position: absolute;
    bottom: 0;
    right: 1rem;
    font-size: 0.8rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
  }
  
  .figure-img1, .figure-img2 {
    margin-bottom: 0px;
  }
  
  .figure-img1 img {
    width: 80%;  
    max-width: 100px;  
    border-radius: 60%; 
  }
  
  .figure-img2 {
    text-align: center;
  }
  
  .figure-img2 .para {
    margin: 0;
    color: #333;  
    font-size: 8px;
  }
}
 
@media (min-width:0px) and (max-width:499px){
  .swiper{
    width: 100%;
    height: 190px;
  }
  .swiper-slide {
    text-align: center;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .swiper-slide img {
    max-width: 3rem;
    height: 1rem;
    display: block;
    object-fit: cover;
    border-radius: 50%;
    /* border: 0.5 rem solid var(--helper--tint); */
  }
  .swiper-clinet-msg {
    padding: 0rem 0rem;
    background: var(--white);
    border-radius: 1rem;
    text-align: left;
    box-shadow: var(--shadow);
    position: relative;
    size: 0px;
  }
  .swiper-client-msg::before {
    content: "";
    position: absolute;
    bottom: 0rem;
    left: 50%;
    transform: transtateX(-50%);
    border: 5rem solid var(--white);
    border-color: transparent;
    border-top-color: var(--helper--tint);
  }
  .swiper-clinet-msg p::before {
    content: "\f10d";
    position: absolute;
    top: 0;
    left: 0rem;
    top: -1rem;
    font-size: 0.8rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-msg p::after {
    content: "\f10e";
    position: absolute;
    bottom: 0;
    right: 1rem;
    font-size: 0.8rem;
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    color: #DEEB04;
  }
  
  .swiper-clinet-data {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  
  .figure-img1, .figure-img2 {
    margin-bottom: 5px;
  }
  
  .figure-img1 img {
    width: 80%;  
    max-width: 100px;  
    border-radius: 60%; 
    text-align: center;
  }
  
  .figure-img2 {
    text-align: center;
  }
  
  .figure-img2 .para {
    margin: 0;
    color: #333;  
    font-size: 6px;  
  }
}


@media (min-width:500px) and (max-width:575px){
  .map{
    width: 500px;
    height: 400px;
  }
}

@media (min-width:0px) and (max-width:499px){
  .map{
    width: 350px;
    height: 300px;
  }
}
 


section{
  min-height: 100vh;
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title{
  font-size: 3rem;
  margin: 2rem 0rem;
}

.faq{
  max-width: 700px;
  margin-top: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid white;
  cursor: pointer;
  display:flex;
  flex-direction: column;
}

.question{
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.question h3{
  font-size: 1.8rem;
}

.answer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1.4s ease;

}

.answer p{
  padding-top: 1rem;
  line-height: 1.6;
  font-size: 1.2rem;
}

.faq.active .answer{
  max-height: 300px;
  animation: fade 1s ease-in-out;
}

.faq.active svg{
  transform: rotate(180deg);
}

.align{
  display:flex;

}

@keyframes fade{
  from{
      opacity: 0;
      transform: translateY(-10px);
  }
  to{
      opacity: 1;
      transform: translateY(0px);
  }
}


.container h1{
  color: #fff;
  text-align: center;
 
}

details{
  background-color: #303030;
  color: #fff;
  font-size: 1.5rem;
  border-radius: 10px;
}

.paragraph{
  font-size: 1rem;
}

summary {
  padding: .5em 1.3rem;
  list-style: none;
  display: flex;
  justify-content: space-between;  
  transition: height 1s ease;
}

summary::-webkit-details-marker {
  display: none;
}

summary:after{
  content: "\002B";
}

details[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: .5em;
}

details[open] summary:after{
  content: "\00D7";
}

details[open] div{
  padding: .5em 1em;
}

/* .swiper-pagination-bullets {
  display: none;
} */



/* Review */

.ag-format-container {
  width: 1142px;
  margin: 0 auto;
}
 
.ag-courses_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;

  padding: 50px 0;
}
.ag-courses_item {
  -ms-flex-preferred-size: calc(33.33333% - 30px);
  flex-basis: calc(33.33333% - 30px);

  margin: 0 15px 30px;

  overflow: hidden;

  border-radius: 28px;
}
.ag-courses-item_link {
  display: block;
  padding: 30px 20px;
  background-color: #121212;

  overflow: hidden;
  max-height: 50;
  max-width: 50;

  position: relative;
}
.ag-courses-item_link:hover,
.ag-courses-item_link:hover .ag-courses-item_date {
  text-decoration: none;
  color: #FFF;
}
.ag-courses-item_link:hover .ag-courses-item_bg {
  -webkit-transform: scale(10);
  -ms-transform: scale(10);
  transform: scale(10);
}
.ag-courses-item_title {
  min-height: 87px;
  margin: 0 0 25px;

  overflow: hidden;

  font-weight: bold;
  font-size: 15px;
  color: #FFF;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date-box {
  font-size: 18px;
  color: #FFF;

  z-index: 2;
  position: relative;
}
.ag-courses-item_date {
  font-weight: bold;
  color: #f9b234;

  -webkit-transition: color .5s ease;
  -o-transition: color .5s ease;
  transition: color .5s ease
}
.ag-courses-item_bg {
  height: 128px;
  width: 128px;
  background-color:#DEEB04;

  z-index: 1;
  position: absolute;
  top: -75px;
  right: -75px;

  border-radius: 50%;

  -webkit-transition: all .5s ease;
  -o-transition: all .5s ease;
  transition: all .5s ease;
}
.ag-courses_item:nth-child(2n) .ag-courses-item_bg {
  background-color: #DEEB04;
}
.ag-courses_item:nth-child(3n) .ag-courses-item_bg {
  background-color: #DEEB04;
}
.ag-courses_item:nth-child(4n) .ag-courses-item_bg {
  background-color: #DEEB04;
}
.ag-courses_item:nth-child(5n) .ag-courses-item_bg {
  background-color: #DEEB04;
}
.ag-courses_item:nth-child(6n) .ag-courses-item_bg {
  background-color: #DEEB04;
}



@media only screen and (max-width: 979px) {
  .ag-courses_item {
    -ms-flex-preferred-size: calc(50% - 30px);
    flex-basis: calc(50% - 30px);
  }
  .ag-courses-item_title {
    font-size: 24px;
  }
}

@media only screen and (max-width: 767px) {
  .ag-format-container {
    width: 96%;
  }

}
@media only screen and (max-width: 639px) {
  .ag-courses_item {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .ag-courses-item_title {
    min-height: 72px;
    line-height: 1;

    font-size: 24px;
  }
  .ag-courses-item_link {
    padding: 22px 40px;
  }
  .ag-courses-item_date-box {
    font-size: 16px;
  }
}




/* Card */

:root {
  --surface-color: #fff;
  --curve: 40;
}

* {
  box-sizing: border-box;
}
 

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 5vw;
  padding: 0;
  list-style-type: none;
}

.card {
  position: relative;
  display: block;
  height: 100%;  
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}

.card__image {      
  width: 100%;
  height: auto;
}

.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;      
  border-radius: calc(var(--curve) * 1px);    
  background-color: var(--surface-color);      
  transform: translateY(100%);
  transition: .2s ease-in-out;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;    
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;      
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}       

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;      
  border-radius: 50%;      
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #000000;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";  
  font-size: .8em; 
  color: #D7BDCA;  
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #000000; 
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
  font-family: "roboto";
  font-size: 15px;
} 



/* droppdown Menu */

 
.dropdown-content {
  display: none;
  background: #f1f1f1;
  min-width: 160px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.dropdown-content a {
  display: block;
  color: #000;
  text-decoration: none;
  padding: 17px;
  font-size: 18px;
}

.dropdown-content a:hover {
  background: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}


/* dropdown */

.contain {
  display: flex;
  justify-content: space-between;
}
.heading{
  font-family: "roboto";
  color: black;
  font-weight: bold;
  margin-bottom: 0rem;
  font-size: 1rem;
  border-bottom: 1px solid lightgray;
}
.heading:hover{
  color: #FFA500;
}
.subHead{
  font-family: "roboto";
  color: black;
  font-weight: bold;
  margin-bottom: 0rem;
  font-size: 1rem;
}
.subHead:hover{
  color: #FFA500;
}
.column {
  border-right: 10px solid white;
}
.column:last-child {
  border-right: none;
}
 
.promo-box {
  background-color: #FFA500;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 200px;
  text-align: start;
}
.promo-box p {
  margin: 0;
  font-size: 12px;
  color: #000;
  font-family: "roboto";
  font-weight: bold;
}
.box {
  margin: 0;
  font-size: 18px;
  font-family: "roboto";
  font-weight: bold;
}


 
/* faq section */

.faq-section {
  background: #fdfdfd;
  min-height: 100vh;
  padding: 10vh 0 0;
  width: 100%;
}
.faq-title h2 {
position: relative;
margin-bottom: 45px;
display: inline-block;
font-weight: 600;
line-height: 1;
}
.faq-title h2::before {
  content: "";
  position: absolute;
  left: 50%;
  width: 60px;
  height: 2px;
  background: #7971EA;
  bottom: -25px;
  margin-left: -30px;
}
.faq-title p {
padding: 0 190px;
margin-bottom: 10px;
}

.faq {
background: #FFFFFF;
box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.06);
border-radius: 4px;
}

.faq .card {
border: none;
background: none;
border-bottom: 1px dashed #CEE1F8;
}

.faq .card .card-header {
padding: 0px;
border: none;
background: none;
-webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
}

.faq .card .card-header:hover {
  background: #FAF9F6;
  padding-left: 10px;
}
.faq .card .card-header .faq-title {
width: 100%;
text-align: left;
padding: 0px;
padding-left: 30px;
padding-right: 30px;
font-weight: 400;
font-size: 15px;
letter-spacing: 1px;
color: #3B566E;
text-decoration: none !important;
-webkit-transition: all 0.3s ease 0s;
-moz-transition: all 0.3s ease 0s;
-o-transition: all 0.3s ease 0s;
transition: all 0.3s ease 0s;
cursor: pointer;
padding-top: 20px;
padding-bottom: 20px;
}

.faq .card .card-header .faq-title .badge {
display: inline-block;
width: 20px;
height: 20px;
line-height: 14px;
float: left;
-webkit-border-radius: 100px;
-moz-border-radius: 100px;
border-radius: 100px;
text-align: center;
background: #7971EA;
color: #fff;
font-size: 12px;
margin-right: 20px;
}

.faq .card .card-body {
padding: 30px;
padding-left: 35px;
padding-bottom: 16px;
font-weight: 400;
font-size: 16px;
color: #6F8BA4;
line-height: 28px;
letter-spacing: 1px;
border-top: 1px solid #F3F8FF;
}

.faq .card .card-body p {
margin-bottom: 14px;
}

@media (max-width: 991px) {
.faq {
  margin-bottom: 30px;
}
.faq .card .card-header .faq-title {
  line-height: 26px;
  margin-top: 10px;
}
}
   

